import { BrowserRouter as Router } from "react-router-dom";
import HomePage from "../components/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
    return (
        <Router>
            <HomePage />
        </Router>
    );
};

export default Home;
