import styled from "styled-components";

export const SliderWrapper = styled.div`
    width: 100%;
    height: 90px;
    z-index: 999;
    color: white;
    margin-top: 50px;
    .slick-initialized .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const BrandWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const SliderTitle = styled.div`
    color: white;
    opacity: 0.8;
    margin-top: 50px;
    font-size: 24px;
    font-weight: 400;
`;

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 50px;
`;

export const Image = styled.img``;

export const Wrapper = styled.img`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
