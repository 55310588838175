import styled from "styled-components";

import Bg from "../../images/bg.jpeg";

export const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    min-height: calc(100vh - 90px);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
        url(${Bg}) 50% 50% / cover;
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
`;

export const ImageWrapper = styled.div`
    width: 200px;
    height: 200px;
    margin-bottom: 50px;
    margin-top: 25px;
`;

export const SloganText = styled.div`
    text-align: center;
    font-size: 36px;
    font-weight: 900;
    color: white;
`;
export const Overlay = styled.div``;

export const BoxWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const Box = styled.div`
    align-items: flex-start;
    background: white;
    color: black;
    display: flex;
    flex-direction: column;
    height: 239px;
    justify-content: flex-start;
    margin-bottom: 30px;
    margin-right: 30px;
    padding: 41px 20px 20px 20px;
    width: 281px;
    border-radius: 5px;
    text-decoration: none;

    @media only screen and (max-width: 600px) {
        margin-right: 0;
    }
`;

export const Title = styled.h2``;

export const Mail = styled.a`
    text-decoration: none;
    color: black;
    margin-left: 5px;
`;

export const Text = styled.p`
    color: black;
    text-decoration: none;
    margin-left: 5px;
`;

export const DisplayWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
`;

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
`;

export const DisplayMaps = styled.span`
    font-weight: 700;
    font-size: 18px;
    color: black;
`;

export const FooterWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    background-color: white;
`;

export const IconWrapper = styled.a`
    margin-right: 15px;
`;
