import Slider from "react-slick";
import { Image, ImageWrapper, SliderWrapper, Wrapper } from "./style";
import KipImg from "../../images/kip-logo.png";
import RavmenImg from "../../images/ravmen-logo.png";

const BrandSlider = () => {
    const sliderSettings = {
        dots: true,
        pauseOnHover: false,
        speed: 200,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <SliderWrapper>
            <Slider {...sliderSettings}>
                {/* <ImageWrapper>
                    <Image src={KipImg} />
                </ImageWrapper>
                <ImageWrapper>
                    <Image src={RavmenImg} />
                </ImageWrapper>*/}
            </Slider>
        </SliderWrapper>
    );
};

export default BrandSlider;
