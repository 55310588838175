import {
    Box,
    BoxWrapper,
    Container,
    FooterWrapper,
    IconWrapper,
    ImageWrapper,
    Img,
    Mail,
    Overlay,
    SloganText,
    Text,
    TextWrapper,
    Title,
} from "./style";
import LogoImg from "../../images/logo.png";
import BrandSlider from "../Slider";
import { BrandWrapper, Image, SliderTitle } from "../Slider/style";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const HomePage = () => {
    return (
        <Overlay>
            <Container>
                <ImageWrapper>
                    <Img src={LogoImg} />
                </ImageWrapper>
                <BoxWrapper>
                    <Box>
                        <Title>Adres</Title>
                        <TextWrapper>
                            <Text>
                                Paşaköy Mah. D-100 Karayolu Cad. Zemin Kat 14
                                Burda AVM Merkez/Bolu
                            </Text>
                        </TextWrapper>
                    </Box>
                    <Box>
                        <Title>Telefon</Title>
                        <TextWrapper as="a" href="tel:+905324499714">
                            <PhoneInTalkIcon style={{ color: "black" }} />
                            <Text>0532 449 97 14</Text>
                        </TextWrapper>

                        <TextWrapper as="a" href="tel:03743000014">
                            <PhoneInTalkIcon style={{ color: "black" }} />
                            <Text>0374 300 00 14</Text>
                        </TextWrapper>
                    </Box>
                    <Box>
                        <Title>Mail</Title>
                        <TextWrapper>
                            <EmailIcon style={{ color: "black" }} />
                            <Mail as="a" href="mailto:ecgrupbolu@gmail.com">
                                ecgrupbolu@gmail.com
                            </Mail>
                        </TextWrapper>
                    </Box>
                </BoxWrapper>
                <SloganText>Kalite Tesadüf Değildir</SloganText>
                <BrandWrapper>
                    <BrandSlider />
                </BrandWrapper>
            </Container>
            <FooterWrapper>
                <IconWrapper
                    style={{ color: "black" }}
                    href="https://www.facebook.com/EC-Grup-Bolu-103577475521246"
                    target="_blank"
                >
                    <FacebookIcon />
                </IconWrapper>
                <IconWrapper
                    style={{ color: "black" }}
                    href="https://www.instagram.com/ecgrupbolu/"
                    target="_blank"
                >
                    <InstagramIcon />
                </IconWrapper>
            </FooterWrapper>
        </Overlay>
    );
};

export default HomePage;
